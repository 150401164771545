import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import * as buffer from "buffer";

// Set up Buffer for use in the browser environment
window.Buffer = buffer.Buffer;

// Connect to Solana mainnet
const heliusRpcUrl = "https://cool-delicate-road.solana-mainnet.quiknode.pro/d36be3f75d13d567e4c406080ea84398871e38fc";
const connection = new Connection(heliusRpcUrl, "confirmed");

// Function: Transfer SOL from one account to another
export const transfer = async (provider, publicKey, destPubKey) => {
  try {
    console.log("Starting SOL transfer...");
    console.log("Public Key:", publicKey.toString());
    console.log("Destination Public Key:", destPubKey);

    const senderPublicKey = new PublicKey(publicKey);
    const receiverPublicKey = new PublicKey(destPubKey);

    const initialBalance = await getSol(senderPublicKey);
    console.log("Fetched Initial Balance:", initialBalance);

    if (initialBalance < 0.02) {
      console.warn("Warning: Low balance. Transaction may fail.");
      throw new Error("Insufficient balance for the transaction.");
    }

    console.log("Initializing the transaction...");
    const amount = 0.02 * LAMPORTS_PER_SOL; // Amount to transfer in lamports

    let transaction = new Transaction({
      feePayer: senderPublicKey,
      recentBlockhash: (await connection.getLatestBlockhash()).blockhash,
    });

    transaction.add(
      SystemProgram.transfer({
        fromPubkey: senderPublicKey,
        toPubkey: receiverPublicKey,
        lamports: amount,
      })
    );

    console.log("Transaction Details Before Signing:", transaction);

    console.log("Prompting Phantom Wallet for transaction signing...");
    if (!provider || !provider.signTransaction) {
      throw new Error("Phantom Wallet is not connected.");
    }

    transaction = await provider.signTransaction(transaction);
    console.log("Transaction successfully signed:", transaction);

    console.log("Sending the transaction to the network...");
    const transactionId = await connection.sendRawTransaction(transaction.serialize(), {
      skipPreflight: false,
    });

    console.log(`Transaction ID: ${transactionId}`);
    console.log("Waiting for confirmation...");

    // Confirm the transaction with retry logic
    const confirmation = await confirmTransactionWithRetry(transactionId, 60);
    console.log(`Transaction confirmed in slot: ${confirmation.slot}`);

    const updatedBalance = await getSol(senderPublicKey);
    console.log("Updated Balance After Transaction:", updatedBalance);

    return {
      success: true,
      transactionId: transactionId,
      balance: updatedBalance,
    };
  } catch (e) {
    console.error("Error during transaction:", e.message || e);
    return { error: e.message || "Transaction failed" };
  }
};

// Function to get the SOL balance of a given public key
const getSol = async (key) => {
  try {
    if (!(key instanceof PublicKey)) {
      key = new PublicKey(key); // Convert to PublicKey
    }

    console.log("Fetching balance for:", key.toString());
    const balance = await connection.getBalance(key);
    console.log("Raw Balance in Lamports:", balance);
    return balance / LAMPORTS_PER_SOL; // Convert lamports to SOL
  } catch (e) {
    console.error("Error fetching balance:", e);
    return 0;
  }
};

// Function to confirm a transaction with retry logic
const confirmTransactionWithRetry = async (transactionId, timeoutSeconds = 60) => {
  const startTime = Date.now();
  const timeout = timeoutSeconds * 1000;

  while (Date.now() - startTime < timeout) {
    const status = await connection.getSignatureStatus(transactionId, {
      searchTransactionHistory: true,
    });

    if (status.value && status.value.confirmationStatus === "finalized") {
      console.log("Transaction successfully finalized:", status.value);
      return status.value;
    }

    console.log("Waiting for transaction confirmation...");
    await new Promise((resolve) => setTimeout(resolve, 2000));
  }

  throw new Error(
    `Transaction was not confirmed in ${timeoutSeconds} seconds. It is unknown if it succeeded or failed. Check signature ${transactionId} using the Solana Explorer or CLI tools.`
  );
};
